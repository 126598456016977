import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Article, Chapter } from 'src/models/playbook';
import type { AppThunk } from 'src/store';
// import axios from 'axios';
import axios from 'src/utils/axios';

interface PlaybookState {
  articles: {
    byId: Record<string, Article>;
    allIds: string[];
  };
  chapter: Chapter[];
  sidebarOpen: boolean;
}

const initialState: PlaybookState = {
  articles: {
    byId: {},
    allIds: []
  },
  chapter: [],
  sidebarOpen: false
};

const slice = createSlice({
  name: 'playbook',
  initialState,
  reducers: {
    getChapters(
      state: PlaybookState,
      action: PayloadAction<{ chapter: Chapter[] }>
    ) {
      const { chapter } = action.payload;
      state.chapter = chapter;
    },

    openSidebar(state: PlaybookState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: PlaybookState): void {
      state.sidebarOpen = false;
    }
  }
});

export const reducer = slice.reducer;

export const getChapters = (): AppThunk => async (dispatch) => {
  const response = await axios.get<{ chapter: Chapter[] }>(
    '/api/playbook/chapters'
  );

  dispatch(slice.actions.getChapters(response.data));
};

export const openSidebar =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openSidebar());
  };

export const closeSidebar =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closeSidebar());
  };

export default slice;
