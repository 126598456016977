import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// DSR User General

const Profile = Loader(lazy(() => import('src/content/pages/User/UserDetail')));
const ChangePassword = Loader(lazy(() => import('src/content/pages/User/Password')));

const userRoutes = [
  {
    path: '/',
    element: <Navigate to="profile" replace />
  },
  {
    path: 'profile',
    children: [
      {
        path: '/',
        element: <Profile />
      },
      {
        path: 'pass',
        element: <ChangePassword />
      }
    ]
  }
];

export default userRoutes;
