import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import type { Notification } from 'src/models/user';

const AnimatedBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        box-shadow: 0 0 0 2px ${theme.palette.background.paper};
        background-color: #44b700;
        color: #44b700;
        
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: ripple 1.2s infinite ease-in-out;
            border: 1px solid currentColor;
            content: "";
        }
    }
`
);

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [cntRead, setCntRead] = useState(0);
  const location = useLocation();
  const theme = useTheme();

  const getNotifications = useCallback(
    async (status = null) => {
      try {
        const response = await axios.get<Notification[]>(
          `${globalConfig.apiUrl}/api/auth/notifications`
        );
        if (isMountedRef.current) {
          setNotifications(response.data);

          let tmpCnt = 0;
          await response.data.map((dt) => {
            if (!dt.isRead) tmpCnt++;
          });
          setCntRead(tmpCnt);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handleOpen = async () => {
    setOpen(true);
    try {
      const response = await axios.put(
        `${globalConfig.apiUrl}/api/auth/notifications/read`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleFormatDate = (timestamp) => {
    if (timestamp)
      return moment(
        timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
      )
        .utcOffset(8)
        .format('DD MMM YYYY');
    else return '';
  };

  const handleNavigate = (notif) => {
    setOpen(false);
    if (notif.type == 'order')
      return navigate(
        `/${location.pathname.split('/')[1]}/orders/view/${notif.linkId}`
      );
    else if (notif.type == 'transaction') {
      if (user.role !== 'Staff')
        return navigate(`/${location.pathname.split('/')[1]}/transactions`);
      else return null;
    }
  };

  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonWrapper color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={cntRead}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List
          sx={{
            p: 2
          }}
        >
          {notifications.length > 0 &&
            notifications.map((notif) => {
              return (
                <>
                  <ListItem
                    sx={{
                      display: { xs: 'block', sm: 'flex' }
                    }}
                    onClick={(event) => handleNavigate(notif)}
                    button
                  >
                    <Box display="flex" flexDirection="column" flex={1}>
                      <Typography variant="body2" color="text.secondary">
                        {handleFormatDate(notif.created)}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        {notif.type == 'order'
                          ? `Order ID: ${notif.relatedId}`
                          : `Transaction ID: ${notif.relatedId}`}
                      </Typography>

                      <Typography
                        component="span"
                        variant="h5"
                        color="text.secondary"
                      >
                        {notif.message}
                      </Typography>
                    </Box>
                  </ListItem>
                  <Divider
                    sx={{
                      my: 1
                    }}
                    component="li"
                  />
                </>
              );
            })}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
