import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// ID Admin Route

const Dashboard = Loader(lazy(() => import('src/content/id-admin/Dashboard')));

const Purchase = Loader(lazy(() => import('src/content/id-admin/Purchase')));
const CreatePurchase = Loader(
  lazy(() => import('src/content/id-admin/Purchase/create'))
);
const SummaryPurchase = Loader(
  lazy(() => import('src/content/id-admin/Purchase/create/Summary'))
);

const Books = Loader(lazy(() => import('src/content/id-admin/Books')));
const CreateBooks = Loader(
  lazy(() => import('src/content/id-admin/Books/create'))
);

const Transactions = Loader(
  lazy(() => import('src/content/id-admin/Transactions'))
);

const TokenLogs = Loader(lazy(() => import('src/content/id-admin/Tokens')));

const Users = Loader(lazy(() => import('src/content/id-admin/Users')));
const CreateUser = Loader(
  lazy(() => import('src/content/id-admin/Users/create'))
);
const SingleUser = Loader(
  lazy(() => import('src/content/id-admin/Users/single'))
);

const Orders = Loader(lazy(() => import('src/content/id-admin/Orders')));
const SingleOrder = Loader(
  lazy(() => import('src/content/id-admin/Orders/single'))
);

const Showrooms = Loader(lazy(() => import('src/content/id-admin/Showrooms')));
const SingleShowroom = Loader(
  lazy(() => import('src/content/id-admin/Showrooms/single'))
);

const Profile = Loader(lazy(() => import('src/content/id-admin/Profile')));

const Logout = Loader(lazy(() => import('src/content/id-admin/Logout')));

const idRoutes = [
  {
    path: '/',
    element: <Navigate to="dashboard" replace />
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'purchase',
    children: [
      {
        path: '/',
        element: <Purchase />
      },
      {
        path: 'create/:id',
        element: <CreatePurchase />
      },
      {
        path: 'summary/:id',
        element: <SummaryPurchase />
      }
    ]
  },
  {
    path: 'books',
    children: [
      {
        path: '/',
        element: <Books />
      },
      {
        path: 'create/:id',
        element: <CreateBooks />
      }
    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '/',
        element: <Orders />
      },
      {
        path: 'view/:id',
        element: <SingleOrder />
      }
    ]
  },
  {
    path: 'showrooms',
    children: [
      {
        path: '/',
        element: <Showrooms />
      },
      {
        path: 'view/:id',
        element: <SingleShowroom />
      }
    ]
  },
  {
    path: 'transactions',
    children: [
      {
        path: '/',
        element: <Transactions />
      }
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <Users />
      },
      {
        path: 'view/:id',
        element: <SingleUser />
      },
      {
        path: 'create',
        element: <CreateUser />
      }
    ]
  },
  {
    path: 'tokens',
    children: [
      {
        path: '/',
        element: <TokenLogs />
      }
    ]
  },
  {
    path: 'profile',
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  },
  {
    path: 'logout',
    children: [
      {
        path: '/',
        element: <Logout />
      }
    ]
  }
];

export default idRoutes;
