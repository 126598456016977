import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// DSR Admin Route
const Dashboard = Loader(lazy(() => import('src/content/dsr-admin/Dashboard')));

const Calendar = Loader(lazy(() => import('src/content/dsr-admin/Calendar')));

const Users = Loader(lazy(() => import('src/content/dsr-admin/Users')));
const SingleUser = Loader(
  lazy(() => import('src/content/dsr-admin/Users/single'))
);

const Crews = Loader(lazy(() => import('src/content/dsr-admin/Crews')));
const SingleCrew = Loader(
  lazy(() => import('src/content/dsr-admin/Crews/single'))
);

const Companies = Loader(lazy(() => import('src/content/dsr-admin/Companies')));
const SingleCompany = Loader(
  lazy(() => import('src/content/dsr-admin/Companies/single'))
);

const Services = Loader(lazy(() => import('src/content/dsr-admin/Services')));
const SingleService = Loader(
  lazy(() => import('src/content/dsr-admin/Services/setting'))
);

const Packages = Loader(lazy(() => import('src/content/dsr-admin/Packages')));
const CreatePackage = Loader(
  lazy(() => import('src/content/dsr-admin/Packages/create'))
);
const UpdatePackage = Loader(
  lazy(() => import('src/content/dsr-admin/Packages/update'))
);

const Transactions = Loader(
  lazy(() => import('src/content/dsr-admin/Transactions'))
);

const Orders = Loader(lazy(() => import('src/content/dsr-admin/Orders')));
const CreateOrder = Loader(
  lazy(() => import('src/content/dsr-admin/Orders/create'))
);
const SingleOrder = Loader(
  lazy(() => import('src/content/dsr-admin/Orders/single'))
);

const Showrooms = Loader(lazy(() => import('src/content/dsr-admin/Showrooms')));
const UpdateShowroom = Loader(
  lazy(() => import('src/content/dsr-admin/Showrooms/update'))
);

const adminRoutes = [
  {
    path: '/',
    element: <Navigate to="dashboard" replace />
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'calendar',
    children: [
      {
        path: '/',
        element: <Calendar />
      }
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'view/:id',
        element: <SingleUser />
      }
    ]
  },
  {
    path: 'companies',
    children: [
      {
        path: '/',
        element: <Companies />
      },
      {
        path: 'view/:id',
        element: <SingleCompany />
      }
    ]
  },
  {
    path: 'crews',
    children: [
      {
        path: '/',
        element: <Crews />
      },
      {
        path: 'view/:id',
        element: <SingleCrew />
      }
    ]
  },
  {
    path: 'services',
    children: [
      {
        path: '/',
        element: <Services />
      },
      {
        path: 'setting/:id',
        element: <SingleService />
      }
    ]
  },
  {
    path: 'packages',
    children: [
      {
        path: '/',
        element: <Packages />
      },
      {
        path: 'create',
        element: <CreatePackage />
      },
      {
        path: 'update/:id',
        element: <UpdatePackage />
      }
    ]
  },
  {
    path: 'transactions',
    children: [
      {
        path: '/',
        element: <Transactions />
      }
    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '/',
        element: <Orders />
      },
      {
        path: 'create',
        element: <CreateOrder />
      },
      {
        path: 'view/:id',
        element: <SingleOrder />
      }
    ]
  },
  {
    path: 'showrooms',
    children: [
      {
        path: '/',
        element: <Showrooms />
      },
      {
        path: 'update/:id',
        element: <UpdateShowroom />
      }
    ]
  }
];

export default adminRoutes;
