import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Button, Drawer, styled, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Logo from 'src/components/LogoSign';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useAuth from 'src/hooks/useAuth';
import SidebarMenu from './SidebarMenu';
import SidebarTopSection from './SidebarTopSection';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

function Sidebar() {
  const { t }: { t: any } = useTranslation();
  const location = useLocation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const { user } = useAuth();
  const mobileRoles = ['Admin', 'Staff'];

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                width: 52,
                mt: 2
                // mb: 3
              }}
            >
              <Logo />
            </Box>
            {!mobileRoles.includes(user?.role) && (
              <Button
                sx={{
                  mr: 2,
                  minWidth: { xs: 100, sm: 200 }
                }}
                component={RouterLink}
                to={`/${location.pathname.split('/')[1]}/calendar`}
                variant="outlined"
                startIcon={<CalendarTodayIcon fontSize="small" />}
              >
                {t('Calendar')}
              </Button>
            )}
          </TopSection>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>

      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={mobileRoles.includes(user?.role) ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            {!mobileRoles.includes(user?.role) && (
              <TopSection>
                <Box
                  sx={{
                    width: 52,
                    ml: 1,
                    mt: 1,
                    mb: 3
                  }}
                >
                  <Logo />
                </Box>
                <SidebarTopSection />
              </TopSection>
            )}

            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
