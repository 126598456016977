import {
  faAddressCard,
  faBriefcase,
  faBuildingColumns,
  faCamera,
  faCircleUser,
  faClockRotateLeft,
  faCoins,
  faFile,
  faFileImage,
  faFileInvoiceDollar,
  faImages,
  faSignOut,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
import type { ReactNode } from 'react';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const menuItemsStaff: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Book Services',
        icon: faCamera,
        link: '/id-staff/books'
      },
      {
        name: 'Orders',
        icon: faFile,
        link: '/id-staff/orders'
      },
      {
        name: 'Showrooms',
        icon: faImages,
        link: '/id-staff/showrooms'
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: '',
        icon: faSignOut,
        link: '/id-staff/logout'
      }
    ]
  }
];

export const menuItemsAdmin: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Purchase Credit',
        icon: faCoins,
        link: '/id-admin/purchase'
      },
      {
        name: 'Book Services',
        icon: faCamera,
        link: '/id-admin/books'
      },
      {
        name: 'Orders',
        icon: faFile,
        link: '/id-admin/orders'
      },
      {
        name: 'Showrooms',
        icon: faImages,
        link: '/id-admin/showrooms'
      },
      {
        name: 'Transactions',
        icon: faFileInvoiceDollar,
        link: '/id-admin/transactions'
      },
      {
        name: 'Users',
        icon: faCircleUser,
        link: '/id-admin/users'
      },
      {
        name: 'Credit Logs',
        icon: faClockRotateLeft,
        link: '/id-admin/tokens'
      },
      {
        name: 'Profile',
        icon: faAddressCard,
        link: '/id-admin/profile'
      }
    ]
  },
  {
    heading: '',
    items: [
      {
        name: '',
        icon: faSignOut,
        link: '/id-admin/logout'
      }
    ]
  }
];

export const menuItemsCrew: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: faBuildingColumns,
        link: '/crew/dashboard'
      },
      {
        name: 'Orders',
        icon: faFileImage,
        link: '/crew/orders'
      }
    ]
  }
];

export const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: faBuildingColumns,
        link: '/admin/dashboard'
      },
      {
        name: 'Companies',
        icon: faBriefcase,
        link: '/admin/companies'
      },
      {
        name: 'Users',
        icon: faCircleUser,
        link: '/admin/users'
      },
      {
        name: 'Crews',
        icon: faCamera,
        link: '/admin/crews'
      },
      {
        name: 'Services',
        icon: faWrench,
        link: '/admin/services'
      },
      {
        name: 'Packages',
        icon: faCoins,
        link: '/admin/packages'
      },
      {
        name: 'Transactions',
        icon: faFileInvoiceDollar,
        link: '/admin/transactions'
      },
      {
        name: 'Orders',
        icon: faFileImage,
        link: '/admin/orders'
      },
      {
        name: 'Showrooms',
        icon: faImages,
        link: '/admin/showrooms'
      }
    ]
  }
];
