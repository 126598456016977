import { PartialRouteObject } from 'react-router';

import { Navigate } from 'react-router-dom';
import Authenticated from 'src/components/Authenticated';

import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import accountRoutes from './account';
import dsrAdminRoutes from './admin';
import baseRoutes from './base';
import dsrCrewRoutes from './crew';
import idAdminRoutes from './idAdmin';
import idStaffRoutes from './idStaff';
import userGeneralRoutes from './user';

export const router: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: 'base',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '*',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/admin/dashboard" replace />
      },
      {
        path: 'admin',
        children: dsrAdminRoutes
      },
      {
        path: 'user',
        children: userGeneralRoutes
      }
    ]
  }
];

export const routerCrew: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: 'base',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '*',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/crew/dashboard" replace />
      },
      {
        path: 'crew',
        children: dsrCrewRoutes
      },
      {
        path: 'user',
        children: userGeneralRoutes
      }
    ]
  }
];

export const routerIdAdmin: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: 'base',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '*',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/id-admin/dashboard" replace />
      },
      {
        path: 'id-admin',
        children: idAdminRoutes
      },
      {
        path: 'user',
        children: userGeneralRoutes
      }
    ]
  }
];

export const routerIdStaff: PartialRouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: 'base',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: '*',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/id-staff/dashboard" replace />
      },
      {
        path: 'id-staff',
        children: idStaffRoutes
      },
      {
        path: 'user',
        children: userGeneralRoutes
      }
    ]
  }
];
