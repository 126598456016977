import type { Chapter } from 'src/models/playbook';
import { mock } from 'src/utils/axios';

const chapters: Chapter[] = [
  {
    id: 'MLq4Ddh64x7qPybdXQeX',
    displayName: '1. Chapter A',
    name: 'Chapter A',
    index: 1,
    role: ["Director"],
    articles: [
      {
        id: 'asdASdASd',
        name: '',
        summary: '',
        chapterId: 'MLq4Ddh64x7qPybdXQeX',
        displayName: 'article!',
        content: '',
        status: "Draft"
      }
    ]
  },
  {
    id: 'MRaUEuw7GzdDVmoPTaxT',
    displayName: '2. Chapter B',
    name: 'Chapter B',
    index: 2,
    role: ["Director"],
    articles: [
      {
        id: 'dasdasdas',
        name: '',
        summary: '',
        chapterId: 'MRaUEuw7GzdDVmoPTaxT',
        displayName: 'article!',
        content: '',
        status: "Draft"
      }
    ]
  }
];

mock.onGet('/api/playbook/chapters').reply(200, { chapters });
