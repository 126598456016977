import { Box, Container, Link, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/LogoSign';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';

const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg',
  LoginCover: '/static/images/placeholders/covers/login_page.png'
};

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  background: ${theme.colors.alpha.white[100]};
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 50%;
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const [isMobile, setIsMobile] = useState<any>(window.innerWidth <= 768);

  useEffect(() => {
    let mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', resize);

    function resize(e) {
      if (e.matches) setIsMobile(false);
      else setIsMobile(true);
    }

    return () => mediaQuery.removeEventListener('change', resize);
  }, []);

  return (
    <>
      <Helmet>
        <title>DSR Login</title>
      </Helmet>
      <Content>
        <MainContent
          sx={{
            width: isMobile ? '100%' : '50%'
          }}
        >
          <Container
            sx={{
              display: 'flex',
              alignItems: 'left',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            {!isMobile && (
              <Box
                sx={{
                  width: 52,
                  mt: 2
                }}
              >
                <Logo />
              </Box>
            )}

            <Box textAlign="left">
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Sign in')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t('Fill in the fields below to sign into your account.')}
              </Typography>
            </Box>
            <JWTLogin />
            <Box my={2}>
              <Typography
                component="span"
                variant="subtitle2"
                color="text.primary"
                fontWeight="bold"
              >
                {t('Forgot your Password?')}
              </Typography>{' '}
              <Link component={RouterLink} to="/account/reset">
                <b>Reset your Password here</b>
              </Link>
            </Box>
            <Box my={2}>
              <Typography
                component="span"
                variant="subtitle2"
                color="text.primary"
                fontWeight="bold"
              >
                {t('Don’t have an account yet?')}
              </Typography>{' '}
              <Link component={RouterLink} to="/account/register">
                <b>Register here</b>
              </Link>
            </Box>
          </Container>
        </MainContent>
        {!isMobile && (
          <SidebarWrapper>
            <Box
              display="flex"
              alignItems="center"
              component="img"
              sx={{
                // mb: 2,
                height: '100%',
                width: '100%'
              }}
              alt="LoginCover"
              src={icons['LoginCover']}
            />
          </SidebarWrapper>
        )}
      </Content>
    </>
  );
}

export default LoginCover;
