import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// DSR Crew Route

const Dashboard = Loader(lazy(() => import('src/content/dsr-crew/Dashboard')));
const Orders = Loader(lazy(() => import('src/content/dsr-crew/Orders')));
const SingleOrder = Loader(
  lazy(() => import('src/content/dsr-crew/Orders/single'))
);

const Calendar = Loader(lazy(() => import('src/content/dsr-crew/Calendar')));

const adminRoutes = [
  {
    path: '/',
    element: <Navigate to="dashboard" replace />
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'calendar',
    children: [
      {
        path: '/',
        element: <Calendar />
      }
    ]
  },
  {
    path: 'orders',
    children: [
      {
        path: '/',
        element: <Orders />
      },
      {
        path: 'view/:id',
        element: <SingleOrder />
      }
    ]
  }
];

export default adminRoutes;
