import { lazy, Suspense } from 'react';

import { Navigate } from 'react-router-dom';
import Guest from 'src/components/Guest';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const LoginCover = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Cover'))
);
const LoginBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Login/Basic'))
);

const RegisterCover = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Cover'))
);
const RegisterBasic = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Basic'))
);
const RegisterWizard = Loader(
  lazy(() => import('src/content/pages/Auth/Register/Wizard'))
);

const RecoverPassword = Loader(
  lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const Activate = Loader(lazy(() => import('src/content/pages/Auth/Activate')));

const ResetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/ResetPassword'))
);
const SetPassword = Loader(
  lazy(() => import('src/content/pages/Auth/SetPassword'))
);

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    )
  },
  {
    path: 'register',
    element: (
      <Guest>
        <RegisterBasic />
      </Guest>
    )
  },
  {
    path: 'activate',
    children: [
      {
        path: '/',
        element: <Navigate to="1" replace />
      },
      {
        path: ':userToken',
        element: <Activate />
      }
    ]
  },
  {
    path: 'reset',
    children: [
      {
        path: '/',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: 'reset-password',
    children: [
      {
        path: '/',
        element: <Navigate to="1" replace />
      },
      {
        path: ':resetToken',
        element: <SetPassword />
      }
    ]
  }
];

export default accountRoutes;
